<template lang="pug">
div
  template(v-if='$slots.sort')
    template(v-for='({ label }, index) in sorts' :key='label')
      button(class='block w-full' @click='activeSort = index')
        slot(name='sort' :label='label' :is-active='index === activeSort')

  template(v-else)
    div(class='select is-small')
      select(v-model='activeSort')
        template(v-for='({ label }, index) in sorts' :key='label')
          option(:value='index') {{ label }}
</template>

<script setup lang="ts">
const route = useRoute()
const $searchContext = inject('$searchContext', null)

const sorts = computed(() => {
  return $searchContext.state.value.sorts
})

const activeSort = computed({
  get() {
    return $searchContext.state.value.currentSort
  },
  set(index: number) {
    updateSort(index)
  },
})

function updateSort(index: number) {
  // If you navigateTo the same routeObj we lose the / at the end of the url
  // which kills overlay isPersistant. So we are early outing instead.
  if (activeSort.value === index) return

  $searchContext.setSort(index)

  const { mod } = route.query

  const routeObj = {
    name: route.name,
    params: {
      ...route.params,
    },
    query: {
      ...(mod && { mod }),
      ...$searchContext.createRouteQuery(),
    },
  }

  navigateTo(routeObj)
}
</script>
